import React from "react"
import { Link, graphql } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Img from "gatsby-image"
import Typewriter from "typewriter-effect/dist/react"
import { Container, Row, Carousel, ListGroup } from "react-bootstrap"

export default function IndexPage({ data }) {
  return (
    <Layout>
      <Seo
        title="Servicio de Web Hosting en Venezuela y Latinoamérica"
        description="Aloja tu página web personal o tienda en linea con el mejor servicio de Web Hosting cPanel, dominio y certificado SSL incluido."
      />
      <section id="hero">
        <div className="hero">
          <Container className="h-100">
            <div className="row h-100 align-items-center pt-5">
              <div className="col-lg-5 my-auto mr-auto">
                <h1 className="display-3 mb-2 pt-5">
                  Crea tu
                  <Typewriter
                    options={{
                      strings: [
                        "página web",
                        "blog",
                        "tienda en linea",
                        "radio",
                      ],
                      autoStart: true,
                      loop: true,
                    }}
                  />
                  con nosotros
                </h1>
                <p className="mb-6 mx-0 mx-lg-15">
                  <ul className="s-hero">
                    <li>Hosting rápido y seguro</li>
                    <li>Certificado SSL Gratis</li>
                    <li>Instalador de aplicaciones con 1 click</li>
                    <li>Migración Gratis</li>
                  </ul>
                </p>
                <div className="mb-4">
                  <Link
                    className="btn btn-primary btn--large"
                    to="/web-hosting/"
                    title="Planes de Hosting"
                  >
                    Empieza ahora
                  </Link>
                </div>
              </div>
            </div>
          </Container>
        </div>
      </section>
      <section id="domainchecker">
        <Container>
          <div className="text-center pt-5 pb-5">
            <h2>Tu gran idea comienza con un nombre de dominio</h2>
            <p>
              Busque nuevos dominios y registre su nombre antes que otra
              persona.
            </p>
            <div className="col-md-6 pb-3 offset-md-3">
              <form
                action="https://my.puntohost.com/order/config/preconfig/dominios"
                method="post"
              >
                <div className="input-group input-group-md pt-3">
                  <input
                    type="search"
                    name="domain"
                    aria-label="Buscar dominio"
                    placeholder="Encuentra tu nombre de dominio"
                    className="form-control"
                  />
                  <span className="input-group-btn">
                    <button
                      className="btn btn-primary btn-lg"
                      type="submit"
                      title="Consultar disponibilidad de dominios"
                    >
                      Buscar
                    </button>
                  </span>
                </div>
              </form>
            </div>
            <div className="d-flex justify-content-center">
              <ListGroup horizontal>
                <ListGroup.Item className="borderless tld font-weight-bold">
                  <span className="text-primary">.com</span>
                  <span> $12.99</span>
                </ListGroup.Item>
                <ListGroup.Item className="borderless tld font-weight-bold">
                  <span className="text-success">.net</span>
                  <span> $12.99</span>
                </ListGroup.Item>
                <ListGroup.Item className="borderless tld font-weight-bold">
                  <span className="text-warning">.org</span>
                  <span> $14.99</span>
                </ListGroup.Item>
                <ListGroup.Item className="borderless tld font-weight-bold">
                  <span className="text-info">.xyz</span>
                  <span> $4.99</span>
                </ListGroup.Item>
              </ListGroup>
            </div>
          </div>
        </Container>
      </section>
      <section id="services">
        <Container>
          <h2 className="text-center pb-3">Lo que tenemos para tí.</h2>
          <Row>
            <div className="col-md-3">
              <div className="card text-center">
                <div className="card-body">
                  <Img
                    style={{ height: "60px" }}
                    objectFit="contain"
                    fluid={data.shared.childImageSharp.fluid}
                    imgStyle={{ objectFit: "contain" }}
                    alt="Web Hosting"
                  />
                  <p className="card-title s-title">Web Hosting</p>
                  <p className="card-text">
                    Adecuado para blogs pequeños, medianos, sitios web
                    personales, de caridad o negocios.
                  </p>
                  <p>
                    Desde: <b>$2.49/mes</b>
                  </p>
                  <Link
                    to="/web-hosting/"
                    title="Planes de Hosting"
                    className="btn btn-primary btn--semi-large"
                  >
                    Obtén mas información
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <div className="card text-center">
                <div className="card-body">
                  <Img
                    style={{ height: "60px" }}
                    fluid={data.reseller.childImageSharp.fluid}
                    alt="Reseller Hosting"
                    imgStyle={{ objectFit: "contain" }}
                  />
                  <p className="card-title s-title">Reseller Hosting</p>
                  <p className="card-text">
                    La solución perfecta para iniciar una pequeña empresa de
                    hosting o alojar varios sitios web.
                  </p>
                  <p>
                    Desde: <b>$12.75/mes</b>
                  </p>
                  <Link
                    to="/reseller-hosting/"
                    title="Planes de Reseller Hosting"
                    className="btn btn-primary btn--semi-large"
                  >
                    Obtén mas información
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <div className="card text-center">
                <div className="card-body">
                  <Img
                    style={{ height: "60px" }}
                    fluid={data.radio.childImageSharp.fluid}
                    alt="Audio Streaming"
                    imgStyle={{ objectFit: "contain" }}
                  />
                  <p className="card-title s-title">Audio Streaming</p>
                  <p className="card-text">
                    Tu radio en la internet con alta fidelidad a tu alcance.
                    Disponible para PC y dispositivos móviles.
                  </p>
                  <p>
                    Desde: <b>$6.99/mes</b>
                  </p>
                  <Link
                    to="/audio-streaming/"
                    title="Planes de Audio Streaming"
                    className="btn btn-primary btn--semi-large"
                  >
                    Obtén mas información
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <div className="card text-center">
                <div className="card-body">
                  <Img
                    style={{ height: "60px" }}
                    fluid={data.vps.childImageSharp.fluid}
                    alt="Servidores VPS"
                    imgStyle={{ objectFit: "contain" }}
                  />
                  <p className="card-title s-title">Servidores VPS</p>
                  <p className="card-text">
                    Adecuado para clientes que requieren más flexibilidad,
                    autonomía y recursos dedicados.
                  </p>
                  <p>
                    Desde: <b>$11.99/mes</b>
                  </p>
                  <Link
                    to="/servidores-vps/"
                    title="Planes de Servidores VPS"
                    className="btn btn-primary btn--semi-large"
                  >
                    Obtén mas información
                  </Link>
                </div>
              </div>
            </div>
          </Row>
        </Container>
      </section>

      <section id="testimonials">
        <Container className="col-lg-12 pt-5 pb-5">
          <Carousel
            role="listbox"
            data-ride="carousel"
            aria-orientation="horizontal"
          >
            <Carousel.Item className="review-padding">
              <Container>
                <Carousel.Caption>
                  <blockquote className="blockquote text-center">
                    <p className="mb-0 client-review-comment">
                      <i className="icon-31"></i> Me encanta Puntohost. Ha sido
                      una relación perfecto. Todos han sido muy atentos a la
                      hora de tener algún problema. Estoy muy contento de poder
                      encontrar una compañia como Puntohost para los servicios
                      de radio por internet.<i className="icon-29"></i>
                    </p>
                    <footer className="blockquote-footer">
                      Teofilo Chacin -{" "}
                      <cite title="GuayoyoAzucarado">GuayoyoAzucarado</cite>
                    </footer>
                    <p className="client-review-stars">
                      <i className="icon-15 stars"></i>
                      <i className="icon-15 stars"></i>
                      <i className="icon-15 stars"></i>
                      <i className="icon-15 stars"></i>
                      <i className="icon-15 stars"></i>
                    </p>
                  </blockquote>
                </Carousel.Caption>
              </Container>
            </Carousel.Item>
            <Carousel.Item className="review-padding">
              <Container>
                <Carousel.Caption>
                  <blockquote className="blockquote text-center">
                    <p className="mb-0 client-review-comment">
                      <i className="icon-31"></i> La comodidad y responsabilidad
                      de puntohost en su servicio de streaming con Electro
                      Colombia Radio nos ofrece una calidad y normas de apoyo
                      como radio de música electrónica, lo cual es una
                      satisfacción total con dicho servicio.
                      <i className="icon-29"></i>
                    </p>
                    <footer className="blockquote-footer">
                      Deejay Kairos -{" "}
                      <cite title="Electro Colombia Radio">
                        Electro Colombia Radio
                      </cite>
                    </footer>
                    <p className="client-review-stars">
                      <i className="icon-15 stars"></i>
                      <i className="icon-15 stars"></i>
                      <i className="icon-15 stars"></i>
                      <i className="icon-15 stars"></i>
                      <i className="icon-15 stars"></i>
                    </p>
                  </blockquote>
                </Carousel.Caption>
              </Container>
            </Carousel.Item>
            <Carousel.Item className="review-padding">
              <Container>
                <Carousel.Caption>
                  <blockquote className="blockquote text-center">
                    <p className="mb-0 client-review-comment">
                      <i className="icon-31"></i> Por años me han dado servicios
                      de primera tanto Streaming, Hosting...Trabaja con rapidez
                      y profesionalismo. Excelente atención. 100% Recomendado!
                      Gracias a ellos tengo una radio online, siendo una de las
                      mejores en Venezuela.<i className="icon-29"></i>
                    </p>
                    <footer className="blockquote-footer">
                      Nestor Blanchart - <cite title="Wepale">Wepale</cite>
                    </footer>
                    <p className="client-review-stars">
                      <i className="icon-15 stars"></i>
                      <i className="icon-15 stars"></i>
                      <i className="icon-15 stars"></i>
                      <i className="icon-15 stars"></i>
                      <i className="icon-15 stars"></i>
                    </p>
                  </blockquote>
                </Carousel.Caption>
              </Container>
            </Carousel.Item>
          </Carousel>
        </Container>
      </section>
    </Layout>
  )
}

export const query = graphql`
  query MyQuery {
    shared: file(relativePath: { eq: "shared.webp" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    reseller: file(relativePath: { eq: "reseller.webp" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    radio: file(relativePath: { eq: "radio.webp" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    vps: file(relativePath: { eq: "vps.webp" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
